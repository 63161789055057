






















































import Vue from 'vue';

interface CProps {
  name: string;
  checked: boolean;
  locked: boolean;
}

export default Vue.extend<{}, {}, {}, CProps>({
  name: 'check-box',
  props: {
    name: String,
    checked: Boolean,
    locked: {
      type: Boolean,
      default: false,
    },
  },
});
